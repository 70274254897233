import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import { Box, Typography, Button } from "@mui/material";

const AWeberFormFallback = () => (
  <Box
    sx={{
      p: 2,
      bgcolor: "#d1ecf1",
      borderRadius: 1,
      color: "#0c5460",
      textAlign: "center",
    }}
  >
    <Typography variant="h6" gutterBottom>
      Form temporarily unavailable
    </Typography>
    We&apos;re having trouble with our subscription service. Please try again
    later. <Typography variant="body2" sx={{ mb: 2 }}></Typography>
    <Button
      variant="contained"
      color="primary"
      onClick={() => window.location.reload()}
    >
      Try Again
    </Button>
  </Box>
);

export const AWeberFormErrorBoundary = ({ children }) => (
  <ErrorBoundary fallback={<AWeberFormFallback />}>{children}</ErrorBoundary>
);
