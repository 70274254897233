import { gql } from "@apollo/client";

export const CHAT_INTERACTION = gql`
  mutation ChatInteraction($input: ChatInteractionInput!) {
    chatInteraction(input: $input) {
      success # virtual field returning boolean
      message # virtual field returning last bot message
      chatInteractionId # virtual field returning ID
    }
  }
`;

export const AUTHORIZE_AWEBER = gql`
  mutation AuthorizeAWeber {
    authorizeAWeber
  }
`;

export const ADD_SUBSCRIBER = gql`
  mutation AddSubscriber(
    $email: String!
    $listId: String!
    $firstName: String
    $lastName: String
    $tags: [String!]
  ) {
    addSubscriber(
      email: $email
      listId: $listId
      firstName: $firstName
      lastName: $lastName
      tags: $tags
    ) {
      success
      message
    }
  }
`;

export const UPDATE_AWEBER_EXPIRATION = gql`
  mutation UpdateAWeberExpiration($id: ID!, $expiresAt: DateTime!) {
    updateAweber(where: { id: $id }, data: { expiresAt: $expiresAt }) {
      id
      expiresAt
    }
  }
`;

export const REFRESH_AWEBER_TOKEN = gql`
  mutation RefreshAWeberToken {
    refreshAWeberToken {
      success
      message
    }
  }
`;
