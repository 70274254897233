import React from "react";
import ErrorBoundary from "./ErrorBoundary";

const ChatErrorFallback = () => (
  <div className="chat-error">
    <h3>Chat temporarily unavailable</h3>
    <p>We&apos;re having trouble loading the chat. Please try again later.</p>
    <button onClick={() => window.location.reload()}>Try Again</button>
  </div>
);

export const ChatErrorBoundary = ({ children }) => (
  <ErrorBoundary fallback={<ChatErrorFallback />}>{children}</ErrorBoundary>
);
