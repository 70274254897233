import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  Modal,
  Alert,
  useTheme,
  useMediaQuery,
  Backdrop,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { addSubscriber } from "../services/aweberService";
import { AWeberFormErrorBoundary } from "./AWeberFormErrorBoundary";

const EmailForm = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const springProps = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateY(0)" : "translateY(-50px)",
    config: { tension: 300, friction: 20 },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);
    try {
      const listId = "6824641";
      const result = await addSubscriber(email, listId, ["Downloaded Report"]);
      if (result.success) {
        setSuccessMessage("Thank you for subscribing!");
        setEmail(""); // Clear the email input
        setTimeout(() => {
          setSuccessMessage(null);
          onClose();
        }, 2000); // Close the form after 2 seconds
      } else {
        setError(result.message || "Failed to submit. Please try again.");
      }
    } catch (err) {
      setError(err.message || "Failed to submit. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(5px)",
        },
      }}
      aria-labelledby="email-form-modal"
    >
      <animated.div
        style={{
          ...springProps,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : "300px",
          maxWidth: "400px",
          maxHeight: "90vh",
          outline: "none",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            overflow: "hidden",
            maxHeight: isMobile ? "80vh" : "auto",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              p: 2,
              bgcolor: "primary.main",
              color: "primary.contrastText",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1">Download Report</Typography>
            <IconButton size="small" onClick={onClose} color="inherit">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          {/* Form */}
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 3,
              gap: 2,
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              fullWidth
            >
              {isSubmitting ? "Sending..." : "Send Report"}
            </Button>
            {error && (
              <Alert severity="error" sx={{ mt: 1 }}>
                {error}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ mt: 1 }}>
                {successMessage}
              </Alert>
            )}
          </Box>
        </Box>
      </animated.div>
    </Modal>
  );
};

// Create a wrapped version of the form
const EmailFormWithErrorBoundary = (props) => (
  <AWeberFormErrorBoundary>
    <EmailForm {...props} />
  </AWeberFormErrorBoundary>
);

// Export the wrapped version by default
export default EmailFormWithErrorBoundary;

// Also export the unwrapped version if needed elsewhere
export { EmailForm };
