import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import NavigationMenu from "../NavigationMenu";
import logo from "../../assets/images/Web-Logo-Light.png";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  boxShadow: "none",
}));

const Logo = styled("img")(({ theme }) => ({
  height: "50px",
  width: "auto",
  maxWidth: "300px",
  marginRight: "20px",
  [theme.breakpoints.down("sm")]: {
    height: "40px",
  },
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: theme.typography.h6.fontFamily,
  fontWeight: theme.typography.h6.fontWeight,
  fontSize: "1rem",
  marginLeft: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.dark,
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

function Header() {
  return (
    <StyledAppBar position="static">
      <Toolbar>
        <Logo src={logo} alt="TrustTech Logo" />
        <div style={{ flexGrow: 1 }} />
        <NavButton component={Link} to="/">
          Home
        </NavButton>
        <NavButton component={Link} to="/articles">
          Articles
        </NavButton>
        <NavButton component={Link} to="/reports">
          Reports
        </NavButton>
        <NavigationMenu />
      </Toolbar>
    </StyledAppBar>
  );
}

export default Header;
