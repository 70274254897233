import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900], // This is typically very close to #333333
  color: theme.palette.common.white,
  padding: theme.spacing(3, 0),
  marginTop: "auto", // This pushes the footer to the bottom if content is short
}));

function Footer() {
  return (
    <StyledFooter component="footer">
      <Typography variant="body2" align="center">
        © {new Date().getFullYear()} TrustTech - All rights reserved
      </Typography>
    </StyledFooter>
  );
}

export default Footer;
