import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ChatComponent from "./ChatComponent";

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: relative;
  padding-bottom: 80px;

  @media (max-width: 768px) {
    padding: 0 20px;
    height: auto;
    min-height: 300px;
  }
`;

const CarouselItemWrapper = styled.div`
  position: absolute;
  width: 400px;
  height: 300px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 480px) {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`;

const MonitorScreen = styled.div`
  width: 100%;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin: 20px auto;
  max-width: 1200px;

  @media (max-width: 768px) {
    height: 300px;
    max-width: 100%;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 145px;
  display: block;
  object-fit: contain;
`;

const NavigationControls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 10;
  padding: 0 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavigationButton = styled.button`
  background: rgba(26, 31, 113, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
  color: white;
  transition: all 0.3s ease;
  z-index: 11;
  position: relative;

  &:hover {
    background: rgba(26, 31, 113, 0.9);
    transform: scale(1.1);
  }
`;

const NavigationDots = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
  }
`;

const Dot = styled.div.attrs((props) => ({
  "data-active": props.active,
}))`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) =>
    props["data-active"] ? "#fff" : "rgba(255, 255, 255, 0.5)"};
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  padding: 16px;
  position: relative;
  z-index: 2;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const ActionButton = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;

  // Full width on mobile
  @media (max-width: 768px) {
    width: 100%;
  }

  // Fixed width on desktop
  @media (min-width: 769px) {
    min-width: 150px;
    width: auto;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
`;

const MonitorContent = styled.div`
  padding: 20px;
  color: white;
  font-family: var(--font-primary);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h3 {
    font-family: var(--font-heading);
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    letter-spacing: 0.3px;
    color: #ffffff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  p {
    font-size: 0.9375rem;
    line-height: 1.6;
    letter-spacing: 0.2px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.95);
  }
`;

const CurvedCarousel = ({ items, onDownloadClick, onItemChange }) => {
  if (!items || !Array.isArray(items)) {
    console.warn("CurvedCarousel: items prop is required and must be an array");
    return null;
  }

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [touchStart, setTouchStart] = useState(null);

  const getStyles = (index) => {
    const count = items.length;
    const theta = (index - activeStep) * ((2 * Math.PI) / count);
    const radius = isMobileView ? 300 : 800;

    return {
      transform: `rotateY(${theta}rad) translateZ(${radius}px)`,
      zIndex: index === activeStep ? 1 : 0,
      opacity: index === activeStep ? 1 : 0.6,
      width: "100%",
      maxWidth: "400px",
      margin: "0 auto",
    };
  };

  const handleNext = useCallback(() => {
    setActiveStep((prev) => (prev < items.length - 1 ? prev + 1 : 0));
  }, [items.length]);

  const handlePrev = useCallback(() => {
    setActiveStep((prev) => (prev > 0 ? prev - 1 : items.length - 1));
  }, [items.length]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    },
    [handleNext, handlePrev],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchStart) return;

    const currentTouch = e.touches[0].clientX;
    const diff = touchStart - currentTouch;

    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        handleNext();
      } else {
        handlePrev();
      }
      setTouchStart(null);
    }
  };

  const renderMonitorContent = (item, index) => {
    const isActive = index === activeStep;

    return (
      <CarouselItemWrapper
        isMobile={isMobileView}
        style={!isMobileView ? getStyles(index) : {}}
        onClick={() => !isMobileView && setActiveStep(index)}
      >
        <MonitorScreen>
          {isActive && item.type === "DOWNLOAD_REPORT" && (
            <VideoContainer>
              <StyledVideo autoPlay muted loop>
                <source src="/videos/test-video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </StyledVideo>
            </VideoContainer>
          )}
          {(!isActive || item.type !== "DOWNLOAD_REPORT") && (
            <MonitorContent>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </MonitorContent>
          )}
        </MonitorScreen>

        {isActive && item.type === "DOWNLOAD_REPORT" && (
          <ButtonContainer>
            <ActionButton
              style={{ backgroundColor: "#1a1f71" }}
              onClick={onDownloadClick}
            >
              Download Report
            </ActionButton>
            <ActionButton
              style={{ backgroundColor: "#ff0000" }}
              onClick={() => setIsChatOpen(true)}
            >
              Talk to AI
            </ActionButton>
          </ButtonContainer>
        )}
      </CarouselItemWrapper>
    );
  };

  useEffect(() => {
    if (onItemChange && items[activeStep]) {
      onItemChange(items[activeStep]);
    }
  }, [activeStep, items, onItemChange]);

  return (
    <>
      <CarouselContainer
        isMobile={isMobileView}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {!isMobileView ? (
          <>
            {items.map((item, index) => renderMonitorContent(item, index))}
            <NavigationControls>
              <NavigationButton onClick={handlePrev}>
                <KeyboardArrowLeft />
              </NavigationButton>
              <NavigationButton onClick={handleNext}>
                <KeyboardArrowRight />
              </NavigationButton>
            </NavigationControls>
          </>
        ) : (
          <SwipeableViews
            index={activeStep}
            onChangeIndex={setActiveStep}
            enableMouseEvents
            style={{
              width: "100%",
              maxWidth: "100%",
              overflow: "hidden",
            }}
            containerStyle={{
              width: "100%",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            {items.map((item, index) => renderMonitorContent(item, index))}
          </SwipeableViews>
        )}

        <NavigationDots>
          {items.map((_, index) => (
            <Dot
              key={index}
              data-active={index === activeStep}
              onClick={() => setActiveStep(index)}
            />
          ))}
        </NavigationDots>
      </CarouselContainer>

      <ChatComponent isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </>
  );
};

export default CurvedCarousel;
