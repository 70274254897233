import React from "react";

const AWeberAuth = () => {
  const handleAWeberAuth = () => {
    window.location.href = "http://localhost:3000/api/aweber/auth";
  };

  return <button onClick={handleAWeberAuth}>Connect AWeber</button>;
};

export default AWeberAuth;
