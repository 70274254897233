import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import backgroundImage from "../assets/images/Landingpage-background.jpg";
import CurvedCarousel from "../components/CurvedCarousel";
import EmailForm from "../components/EmailForm";
import NavigationMenu from "../components/NavigationMenu";

// Animations
const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const BackgroundContainer = styled(Box)({
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  position: "relative",
  padding: "0 20px",
  overflow: "hidden",
  maxWidth: "100vw",
});

const CarouselContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: "1000px",
  marginTop: "2rem",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  padding: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
}));

const GlowingBorder = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: "20px",
  pointerEvents: "none",
  "&::before": {
    content: '""',
    position: "absolute",
    top: -2,
    left: -2,
    right: -2,
    bottom: -2,
    borderRadius: "22px",
    background: "linear-gradient(45deg, #f7f7f7, #333333)",
    zIndex: -1,
    animation: "glowing 3s linear infinite",
  },
});

const MonitorDisplay = styled(Box)({
  width: "80%",
  maxWidth: "800px",
  position: "relative",
  marginBottom: "2rem",
  animation: `${float} 6s ease-in-out infinite`,
  overflow: "visible",
});

const MonitorScreen = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "500px",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  borderRadius: "20px",
  padding: "12px",
  position: "relative",
  backdropFilter: "blur(10px)",
  border: "2px solid #2a2a2a",
  boxShadow: `0 0 30px rgba(0, 128, 255, 0.3),
     inset 0 2px 20px rgba(0, 0, 0, 0.5)`,
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(rgba(255,255,255,0.1) 1px, transparent 1px),
       linear-gradient(90deg, rgba(255,255,255,0.1) 1px, transparent 1px)`,
    backgroundSize: "2px 2px",
    pointerEvents: "none",
    opacity: 0.1,
    borderRadius: "18px",
  },
  [theme.breakpoints.down("md")]: {
    height: "400px",
  },
}));

const MonitorStand = styled(Box)({
  width: "120px",
  height: "80px",
  margin: "0 auto",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-20px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "40px",
    height: "60px",
    background: "linear-gradient(45deg, #1a1a1a, #2a2a2a)",
    borderRadius: "5px",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "0",
    width: "100%",
    height: "20px",
    background: "linear-gradient(45deg, #1a1a1a, #2a2a2a)",
    borderRadius: "10px",
  },
});

const PowerButton = styled(Box)({
  position: "absolute",
  right: "20px",
  bottom: "20px",
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  backgroundColor: "#666",
  "&::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#33ff33",
    boxShadow: "0 0 5px #33ff33",
  },
});

function LandingPage() {
  const [isEmailFormOpen, setIsEmailFormOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const carouselItems = [
    {
      type: "DOWNLOAD_REPORT",
      title: "Download Our Report",
      description: "Get insights into the latest technology trends",
      videoUrl: "/videos/test-video.mp4",
    },
    {
      type: "FEATURE_2",
      title: "Feature 2",
      description: "Explore our second feature",
      imageUrl: "/path/to/feature2-image.jpg",
    },
    {
      type: "FEATURE_3",
      title: "Feature 3",
      description: "Discover more features",
      imageUrl: "/path/to/feature3-image.jpg",
    },
  ];

  const handleOpenEmailForm = () => setIsEmailFormOpen(true);
  const handleCloseEmailForm = () => setIsEmailFormOpen(false);

  const handleCarouselChange = (item) => {
    setActiveItem(item);
  };

  const renderActiveContent = () => {
    if (!activeItem) return null;

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {activeItem.videoUrl ? (
          <video
            autoPlay
            loop
            muted
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          >
            <source src={activeItem.videoUrl} type="video/mp4" />
          </video>
        ) : activeItem.imageUrl ? (
          <img
            src={activeItem.imageUrl}
            alt={activeItem.title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        ) : null}

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 2,
            background: "linear-gradient(to top, rgba(0,0,0,0.8), transparent)",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {activeItem.title}
          </Typography>
          <Typography variant="body1">{activeItem.description}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <BackgroundContainer>
      <NavigationMenu />
      <MonitorDisplay>
        <MonitorScreen>
          <GlowingBorder />
          {activeItem ? (
            renderActiveContent()
          ) : (
            <Box
              sx={{
                p: 4,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                gutterBottom
                align="center"
                sx={{
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  marginBottom: "0.5rem",
                  fontSize: { xs: "2rem", sm: "2.5rem" },
                }}
              >
                Welcome to TrustTech
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                align="center"
                sx={{
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  marginBottom: "2rem",
                  opacity: 0.9,
                }}
              >
                Your trusted source for technology insights
              </Typography>
            </Box>
          )}
        </MonitorScreen>
        <MonitorStand />
        <PowerButton />
      </MonitorDisplay>

      <CarouselContainer>
        <CurvedCarousel
          items={carouselItems}
          onDownloadClick={handleOpenEmailForm}
          onItemChange={handleCarouselChange}
        />
      </CarouselContainer>

      <EmailForm isOpen={isEmailFormOpen} onClose={handleCloseEmailForm} />
    </BackgroundContainer>
  );
}

export default LandingPage;
