import { createTheme } from "@mui/material/styles";

// Import your custom fonts here
// import CustomFont from '../assets/fonts/CustomFont.ttf';

const theme = createTheme({
  palette: {
    primary: {
      main: "#1A1F71", // Deep Blue (your primary brand color)
      light: "#40E0D0", // Turquoise (as a lighter variation)
      dark: "#131753", // A darker shade of Deep Blue
    },
    secondary: {
      main: "#32CD32", // Lime Green
      light: "#87CEEB", // Sky Blue
      dark: "#20B2AA", // Soft Teal
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
    },
  },
  typography: {
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 700, // Bold
      fontSize: "2.25rem", // 36px
    },
    h2: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 600, // Semi-Bold
      fontSize: "1.75rem", // 28px
    },
    h3: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 600, // Semi-Bold
      fontSize: "1.5rem", // 24px (you can adjust this)
    },
    h4: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 600, // Semi-Bold
      fontSize: "1.25rem", // 20px (you can adjust this)
    },
    body1: {
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontWeight: 400, // Regular
      fontSize: "1rem", // 16px
    },
    body2: {
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontWeight: 300, // Light
      fontSize: "0.75rem", // 12px
    },
    button: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 600, // Semi-Bold
      textTransform: "none", // Prevents all-caps
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Open+Sans:wght@300;400&display=swap');
      `,
    },
  },
});

export default theme;
