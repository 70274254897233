import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
  Modal,
  Backdrop,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { useMutation } from "@apollo/client";
import { CHAT_INTERACTION } from "../graphql/mutations";
import { v4 as uuidv4 } from "uuid";
import { logger } from "../utils/logger";

const ChatComponent = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const sessionId = useRef(uuidv4());
  const chatEndRef = useRef(null);
  const inputRef = useRef(null);

  const [chatInteraction] = useMutation(CHAT_INTERACTION, {
    onError: (error) => {
      logger.error("Chat mutation error:", {
        message: error.message,
        networkError: error.networkError,
        graphQLErrors: error.graphQLErrors,
      });
    },
  });

  // Animation for modal entry
  const springProps = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateY(0)" : "translateY(20px)",
    config: { tension: 300, friction: 20 },
  });

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  // Focus input when modal opens
  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const handleSendMessage = async (userMessage) => {
    try {
      // Add user message to chat history
      const newUserMessage = { type: "user", content: userMessage };
      setChatHistory((prev) => [...prev, newUserMessage]);

      // Clear the input immediately after sending
      setMessage("");

      const result = await chatInteraction({
        variables: {
          input: {
            sessionId: sessionId.current,
            message: userMessage,
          },
        },
      });

      logger.info("Chat result:", result);

      // Add AI response to chat history
      if (result.data?.chatInteraction?.message) {
        const newAIMessage = {
          type: "ai",
          content: result.data.chatInteraction.message,
        };
        setChatHistory((prev) => [...prev, newAIMessage]);
      }
    } catch (error) {
      logger.error("Error in chat interaction:", error);
      // Optionally add error message to chat history
      setChatHistory((prev) => [
        ...prev,
        {
          type: "error",
          content: "Sorry, there was an error processing your message.",
        },
      ]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(message);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(5px)",
        },
      }}
      aria-labelledby="chat-modal-title"
    >
      <animated.div
        style={{
          ...springProps,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : "400px",
          maxWidth: "400px",
          maxHeight: "90vh",
          outline: "none",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            display: "flex",
            flexDirection: "column",
            height: isMobile ? "70vh" : "600px",
            maxHeight: isMobile ? "500px" : "600px",
            overflow: "hidden",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              p: 2,
              bgcolor: "primary.main",
              color: "primary.contrastText",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" id="chat-modal-title">
              Chat with AI Assistant
            </Typography>
            <IconButton
              onClick={onClose}
              color="inherit"
              aria-label="close chat"
              edge="end"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Chat Messages */}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              bgcolor: "grey.50",
            }}
          >
            {chatHistory.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  alignSelf: msg.type === "user" ? "flex-end" : "flex-start",
                  maxWidth: "80%",
                }}
              >
                <Box
                  sx={{
                    bgcolor: msg.type === "user" ? "primary.main" : "grey.200",
                    color:
                      msg.type === "user"
                        ? "primary.contrastText"
                        : "text.primary",
                    p: 2,
                    borderRadius:
                      msg.type === "user"
                        ? "20px 20px 0 20px"
                        : "20px 20px 20px 0",
                    boxShadow: 1,
                  }}
                >
                  <Typography variant="body1">{msg.content}</Typography>
                </Box>
              </Box>
            ))}
            <div ref={chatEndRef} />
          </Box>

          {/* Input Area */}
          <Box
            sx={{
              p: 2,
              bgcolor: "background.paper",
              borderTop: 1,
              borderColor: "divider",
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                fullWidth
                multiline
                maxRows={4}
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
                inputRef={inputRef}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 3,
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSendMessage(message)}
                disabled={!message.trim()}
                sx={{
                  minWidth: "auto",
                  borderRadius: 2,
                }}
              >
                <SendIcon />
              </Button>
            </Box>
          </Box>
        </Box>
      </animated.div>
    </Modal>
  );
};

export default ChatComponent;
