import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

export const addSubscriber = async (email, listId, tags = []) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/aweber/subscribe`, {
      email,
      listId,
      tags,
    });
    return { success: true, data: response.data };
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;

      switch (status) {
        case 400:
          if (data.message?.toLowerCase().includes("already subscribed")) {
            throw new Error(
              "This email is already subscribed to our newsletter.",
            );
          }
          if (data.message?.toLowerCase().includes("admin")) {
            throw new Error(
              "This email address cannot be used for subscription.",
            );
          }
          throw new Error("Please check your email address and try again.");

        case 429:
          throw new Error(
            "Too many attempts. Please try again in a few minutes.",
          );

        case 503:
          throw new Error(
            "Our subscription service is temporarily unavailable. Please try again later.",
          );

        default:
          throw new Error(
            "Unable to process your subscription. Please try again later.",
          );
      }
    }

    throw new Error(
      "Connection error. Please check your internet and try again.",
    );
  }
};
