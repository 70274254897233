import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import { ApolloProvider } from "@apollo/client";
import client from "./services/apollo-client"; // Adjust this import path if necessary
import theme from "./styles/theme";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import LandingPage from "./pages/LandingPage";
import "./App.css";
import AWeberAuth from "./components/AweberAuth";
import AWeberCallback from "./components/AweberCallback";
import ErrorBoundary from "./components/ErrorBoundary";
import { ChatErrorBoundary } from "./components/ChatErrorBoundary";
import ChatComponent from "./components/ChatComponent";

function App() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Box display="flex" flexDirection="column" minHeight="100vh">
              <Header />
              <Box component="main" flexGrow={1}>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/connect-aweber" element={<AWeberAuth />} />
                  <Route
                    path="/api/aweber/oauth/callback"
                    element={<AWeberCallback />}
                  />
                  {/* Add more routes here as needed */}
                </Routes>
              </Box>
              <Footer />
              <ChatErrorBoundary>
                <ChatComponent
                  isOpen={isChatOpen}
                  onClose={() => setIsChatOpen(false)}
                />
              </ChatErrorBoundary>
            </Box>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
