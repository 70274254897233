import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AWeberCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get("access_token");
    const refreshToken = searchParams.get("refresh_token");

    if (accessToken && refreshToken) {
      // Store these tokens securely (e.g., in state management or secure storage)
      console.log("Access Token:", accessToken);
      console.log("Refresh Token:", refreshToken);
      // TODO: Implement secure token storage

      // Redirect to a success page or your main app
      navigate("/dashboard");
    } else {
      console.error("Failed to receive tokens");
      // Handle error case, maybe redirect to an error page
      navigate("/error");
    }
  }, [location, navigate]);

  return <div>Processing AWeber authentication...</div>;
};

export default AWeberCallback;
