import React from "react";
import { useSpring, animated } from "react-spring";
import { IconButton, Box, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.h6.fontFamily,
  fontWeight: theme.typography.h6.fontWeight,
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const NavigationMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const slideIn = useSpring({
    transform: isOpen ? "translateX(0%)" : "translateX(100%)",
    opacity: isOpen ? 1 : 0,
  });

  const menuItems = [
    { text: "Home", path: "/" },
    { text: "Articles", path: "/articles" },
    { text: "Reports", path: "/reports" },
  ];

  return (
    <>
      <IconButton
        onClick={() => setIsOpen(true)}
        sx={{
          position: "fixed",
          top: 16,
          right: 16,
          zIndex: 1000,
          color: "white",
          display: { xs: "flex", md: "none" },
        }}
      >
        <MenuIcon />
      </IconButton>

      <animated.div
        style={{
          ...slideIn,
          position: "fixed",
          top: 0,
          right: 0,
          width: "250px",
          height: "100vh",
          backgroundColor: "white",
          zIndex: 1100,
          boxShadow: "-2px 0 5px rgba(0,0,0,0.2)",
        }}
      >
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-start" }}>
          <IconButton onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {menuItems.map((item) => (
            <StyledListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              onClick={() => setIsOpen(false)}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  fontFamily: "inherit",
                  fontWeight: "inherit",
                }}
              />
            </StyledListItem>
          ))}
        </List>
      </animated.div>

      {isOpen && (
        <Box
          onClick={() => setIsOpen(false)}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1050,
          }}
        />
      )}
    </>
  );
};

export default NavigationMenu;
